import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Collapsible from 'react-collapsible';
import { connect } from 'react-redux';

import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import Select from '../Select/Select';
import TextEditor from '../TextEditor/TextEditor';
import TokenField from '../TokenField/TokenField';
import TextArea from '../TextArea/TextArea';
import DatePicker from '../DatePicker/DatePicker';
import Icon from '../Icon/Icon';

import * as domainActions from '../../actions/domainActions';
import * as commonActions from '../../actions/commonActions';

import {
    ALLOWED_FLOAT_KEYS,
    ALLOWED_KEYS,
    BILLING_TYPE_OPTIONS,
    CMS_OPTIONS,
    FORBIDDEN_FLOAT_KEYS,
    FORBIDDEN_KEYS,
    INVENTORY_TYPES,
    VALIDATION_ERRORS,
    VALIDATION_MAP,
    VALIDATION_MAP_HELPERS,
} from '../../utils/domainMap.utils';

import * as validationUtils from '../../utils/validation.utils';

import s from './domainEditor.css';

import variables from '../../css/variables';

export class DomainEditor extends Component {
    static renderUnsafeComponents(key, fieldRenderer) {
        return (
            <div className={s.unsafeSetting} key={key}>
                {fieldRenderer.renderer(key)}
                <p className={s.unsafeText}>Description: {VALIDATION_MAP[key].unsafeReason}</p>
            </div>
        );
    }

    static parseBillingType(value) {
        return Object.keys(BILLING_TYPE_OPTIONS).find(
            option => BILLING_TYPE_OPTIONS[option] === value
        );
    }

    static renderFieldLabel(label, isRequired) {
        return isRequired ? `${label} *` : label;
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.closeModal = this.closeModal.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFeatureFlagChange = this.handleFeatureFlagChange.bind(this);
        this.handleDisplayData = this.handleDisplayData.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTokens = this.handleTokens.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.saveEditedData = this.saveEditedData.bind(this);
        this.handleConfirmSaveDomain = this.handleConfirmSaveDomain.bind(this);
        this.handleCancelSaveDomain = this.handleCancelSaveDomain.bind(this);
        this.handleDisplayEditedData = this.handleDisplayEditedData.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.renderInputNumber = this.renderInputNumber.bind(this);
        this.renderCheckbox = this.renderCheckbox.bind(this);
        this.renderFeatureFlagList = this.renderFeatureFlagList.bind(this);
        this.renderSelect = this.renderSelect.bind(this);
        this.renderTextEditor = this.renderTextEditor.bind(this);
        this.renderTokenField = this.renderTokenField.bind(this);
        this.renderTextArea = this.renderTextArea.bind(this);
        this.renderDatePicker = this.renderDatePicker.bind(this);
        this.handleCancelSalesforceDisabling = this.handleCancelSalesforceDisabling.bind(this);
        this.handleConfirmSalesforceDisabling = this.handleConfirmSalesforceDisabling.bind(this);
        this.handleAdditionalCurrencies = this.handleAdditionalCurrencies.bind(this);
        this.handleReachSynchStateChange = this.handleReachSynchStateChange.bind(this);
        this.handleContentManagementSystem = this.handleContentManagementSystem.bind(this);
        this.handleCreateDigitalInventory = this.handleCreateDigitalInventory.bind(this);

        Object.keys(VALIDATION_MAP).forEach(item => {
            this[`ref_${item}`] = React.createRef();

            if (VALIDATION_MAP[item].type === 'enum') {
                this[`ref_${item}_select`] = React.createRef();
            }
        });
    }

    getInitialState() {
        const { targetDomain } = this.props;
        const keys = Object.keys(VALIDATION_MAP);
        const targetDomainKeys = Object.keys(targetDomain);

        const state = {
            errors: {},
            focusedElement: '',
            lastStrokedKey: '',
        };

        if (targetDomainKeys.length > 0) {
            targetDomainKeys.forEach(key => {
                if (VALIDATION_MAP[key] && targetDomain[key] !== undefined) {
                    if (key === 'billing_type') {
                        state.billing_type =
                            BILLING_TYPE_OPTIONS[targetDomain[key]] || targetDomain[key];
                    } else {
                        state[key] = targetDomain[key];
                    }
                }
                state.openConfirmModal = false;
                state.editedData = {};
                state.isEditing = true;
            });
        } else {
            keys.forEach(key => {
                state[key] = VALIDATION_MAP[key].defaultOption;
            });
        }

        return state;
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEnter);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        this.handleFormScroll();
        this.handleAdditionalCurrencies();
        this.handleReachSynchStateChange();
        this.handleContentManagementSystem();
        this.handleCreateDigitalInventory(prevState);
    }

    componentWillUnmount() {
        const { setServerErrors } = this.props;
        setServerErrors('');
        document.removeEventListener('keydown', this.handleEnter);
    }

    getInputErrors(name, value) {
        const errors = {};
        const validationMap = this.updateValidationMap();
        const validationKeys = Object.values(validationMap[name].validation);
        const validationWithName = ['MinMax', 'MaxLength'];

        validationKeys.forEach(key => {
            const validationMethod = `validate${key}`;

            if (
                validationWithName.includes(key) &&
                validationUtils[validationMethod](name, value)
            ) {
                errors[name] = validationUtils[validationMethod](name, value);
            }

            if (!validationWithName.includes(key) && validationUtils[validationMethod](value)) {
                errors[name] = validationUtils[validationMethod](value);
            }
        });

        this.setState({
            errors,
        });

        return errors;
    }

    handleFormScroll() {
        const fieldToScrollTo = this.parseFieldErrors();

        if (this[`ref_${fieldToScrollTo}`] && this[`ref_${fieldToScrollTo}`].current) {
            this[`ref_${fieldToScrollTo}`].current.scrollIntoView({
                block: 'center',
                inline: 'nearest',
            });
        }
    }

    parseFieldErrors() {
        const { errors } = this.state;
        const { serverError } = this.props;

        const firstStateError = Object.keys(errors)[0];
        const firstServerError = Object.keys(serverError)[0];

        let firstFieldError;

        if (firstStateError) {
            firstFieldError = firstStateError;
        }

        if (firstServerError && firstServerError !== 'footer') {
            if (firstServerError === 'inventory_type') {
                firstFieldError = 'digital_inventory';
            } else {
                firstFieldError = firstServerError;
            }
        }
        return firstFieldError;
    }

    updateValidationMap() {
        const { ...state } = this.state;
        const { targetDomain } = this.props;
        const newValidationMap = {
            ...VALIDATION_MAP,
            cms: {
                ...VALIDATION_MAP.cms,
                disabled: state.isEditing,
            },
            provider_domain_id: {
                ...VALIDATION_MAP.provider_domain_id,
                required: state.digital_inventory,
                validation: state.digital_inventory ? ['Required', 'Number'] : ['Number'],
                disabled: state.cms === CMS_OPTIONS.other,
            },
            digital_inventory: {
                ...VALIDATION_MAP.digital_inventory,
                required: !!state.provider_domain_id,
                validation: state.provider_domain_id ? ['Required'] : [],
                disabled: state.cms === CMS_OPTIONS.other,
            },
            static_inventory: {
                ...VALIDATION_MAP.static_inventory,
                disabled: state.cms === CMS_OPTIONS.other,
            },
            reach_synchronization_enabled: {
                ...VALIDATION_MAP.reach_synchronization_enabled,
                required:
                    !!state.reach_publisher_id ||
                    !!state.use_player_identifiers_in_reach_ad_requests,
                validation:
                    state.reach_publisher_id || state.use_player_identifiers_in_reach_ad_requests
                        ? ['Required']
                        : [],
            },
            reach_publisher_id: {
                ...VALIDATION_MAP.reach_publisher_id,
                required: state.reach_synchronization_enabled,
                validation: state.reach_synchronization_enabled
                    ? ['Required', 'Number']
                    : ['Number'],
            },
            listeners: {
                ...VALIDATION_MAP.listeners,
                required: state.enable_integration_settings,
                validation: state.enable_integration_settings ? ['JSON'] : [],
            },
            salesforce_instance: {
                ...VALIDATION_MAP.salesforce_instance,
                required: state.enable_integration_settings,
                validation: state.enable_integration_settings ? ['Required'] : [],
            },
            static_tenant_url: {
                ...VALIDATION_MAP.static_tenant_url,
                required: state.broadsign_static_enabled,
                validation: state.broadsign_static_enabled ? ['Required'] : [],
            },
            primary_currency: {
                ...VALIDATION_MAP.primary_currency,
                required:
                    state.reach_synchronization_enabled ||
                    state.non_guaranteed_campaign_management_enabled ||
                    state.non_guaranteed_campaign_management_creation_tm,
                validation:
                    state.reach_synchronization_enabled ||
                    state.non_guaranteed_campaign_management_enabled ||
                    state.non_guaranteed_campaign_management_creation_tm
                        ? ['Required']
                        : [],
                disabled:
                    (state.isEditing && targetDomain.non_guaranteed_campaign_management_enabled) ||
                    !!state.non_guaranteed_campaign_management_creation_tm,
            },
            additional_currencies: {
                ...VALIDATION_MAP.additional_currencies,
                disabled: !state.primary_currency,
                options: VALIDATION_MAP.additional_currencies.options.filter(
                    currency => currency !== state.primary_currency
                ),
            },
        };

        return newValidationMap;
    }

    defineComponents() {
        const renderersByType = {
            text: this.renderInput,
            float: this.renderInputNumber,
            number: this.renderInputNumber,
            boolean: this.renderCheckbox,
            featureFlagList: this.renderFeatureFlagList,
            html: this.renderTextEditor,
            listOfNumbers: this.renderTokenField,
            enum: this.renderSelect,
            textarea: this.renderTextArea,
            datePicker: this.renderDatePicker,
        };

        const fields = Object.keys(VALIDATION_MAP).reduce((obj, fieldName) => {
            const field = VALIDATION_MAP[fieldName];
            const renderers = obj;
            renderers[fieldName] = {
                renderer: key =>
                    renderersByType[field.type] ? renderersByType[field.type](key) : null,
            };
            return renderers;
        }, {});

        return fields;
    }

    isDigitalInventoryDisabled() {
        const { ...state } = this.state;
        const { targetDomain } = this.props;

        if ((state.isEditing && targetDomain.digital_inventory) || !state.digital_inventory) {
            return true;
        }

        return false;
    }

    shouldReachSynchronizationBeDisabled() {
        const { ...state } = this.state;
        const { targetDomain } = this.props;
        if (state.isEditing && targetDomain.reach_synchronization_enabled) {
            return true;
        }

        return false;
    }

    shouldReachPublisherIDBeDisabled() {
        const { ...state } = this.state;
        const { targetDomain } = this.props;

        if (
            (state.isEditing && targetDomain.reach_publisher_id) ||
            !state.reach_synchronization_enabled ||
            (state.isEditing && !state.reach_synchronization_enabled)
        ) {
            return true;
        }

        return false;
    }

    isNoAccessBillingTypeSelected() {
        const { ...state } = this.state;
        return state.billing_type === BILLING_TYPE_OPTIONS.no_access;
    }

    shouldNoAccessDisableComponent(key) {
        return (
            (VALIDATION_MAP[key].unsafe || VALIDATION_MAP[key].direct) &&
            this.isNoAccessBillingTypeSelected()
        );
    }

    isUsePlayerIdentifiersInReachAdRequestsDisabled() {
        const { ...state } = this.state;

        return !state.reach_synchronization_enabled;
    }

    generateComponents() {
        const keys = Object.keys(VALIDATION_MAP);

        const components = {
            standard: [],
            unsafe: [],
            feature_flags: [],
            aquarius: [],
            direct: [],
        };
        const fields = this.defineComponents();
        keys.forEach(key => {
            if (VALIDATION_MAP[key].unsafe) {
                components.unsafe.push(DomainEditor.renderUnsafeComponents(key, fields[key]));
            } else if (VALIDATION_MAP[key].feature_flags) {
                components.feature_flags = components.feature_flags.concat(
                    fields[key].renderer(key)
                );
            } else if (VALIDATION_MAP[key].aquarius) {
                components.aquarius.push(fields[key].renderer(key));
            } else if (!this.renderFormForActiveSetting(key)) {
                components.direct.filter(field => fields[key].renderer(key) === field);
            } else if (VALIDATION_MAP[key].direct) {
                components.direct.push(fields[key].renderer(key));
            } else {
                components.standard.push(fields[key].renderer(key));
            }
        });

        return components;
    }

    handleEnter(e) {
        const { focusedElement } = this.state;
        if (e.target && e.target.name === 'toc') {
            return;
        }

        if (e.keyCode === ALLOWED_KEYS.enter) {
            document.getElementById('domainForm').focus();
            if (focusedElement === '') {
                this.handleSubmit();
            } else {
                this.handleBlur(e);
                e.target.blur();
            }
        }
    }

    closeModal() {
        const { resetForm, history } = this.props;
        history.push('/domains');
        resetForm();
    }

    handleBlur(e) {
        const { name, value } = e.target;
        if (name === 'toc') {
            this.setState({
                focusedElement: '',
            });
            return;
        }
        if (VALIDATION_MAP[name].type === 'float') {
            this.getInputErrors(name, parseFloat(value));
            const newValue = parseFloat(value) || null;
            this.setState({
                [name]: newValue,
            });
        } else {
            this.getInputErrors(name, value);
        }
        if (name === 'reach_publisher_id') {
            if (value.trim() === '') {
                this.setState({
                    [name]: null,
                });
            }
        }
        if (name === 'static_tenant_url') {
            if (value.trim() === '') {
                this.setState({
                    [name]: null,
                });
            }
        }
        this.setState({
            focusedElement: '',
        });
    }

    handleChange(e) {
        const { name, value, type } = e.target;
        const { setServerErrors } = this.props;
        const { ...state } = this.state;

        setServerErrors('');
        this.setState({
            errors: {},
        });

        if (type === 'checkbox') {
            const target = e.target.getAttribute('id');
            if (target === 'enable_integration_settings') {
                this.handleSalesforceIntegrationState(
                    state.enable_integration_settings,
                    state.listeners
                );
            } else {
                this.setState(prevState => ({
                    [target]: prevState[target] === null ? true : !prevState[target],
                    isSalesforceIntegrationDisabled: false,
                }));
            }
        } else {
            const newValue = value;
            this.setState({
                [name]: newValue,
            });
        }
    }

    handleFeatureFlagChange(e) {
        const { setServerErrors } = this.props;
        const { ...state } = this.state;

        setServerErrors('');
        this.setState({
            errors: {},
        });

        const flagName = e.target.id;

        const flagsState = [];
        state.feature_flags.forEach(ff => {
            flagsState.push({ ...ff }); // Ensure we update a copy of ff later.
        });

        const idx = flagsState.findIndex(ff => ff.name === flagName);
        flagsState[idx].enabled = !flagsState[idx].enabled;

        this.setState({
            feature_flags: flagsState,
        });
    }

    handleSalesforceIntegrationState(enableSettings, listeners) {
        if (enableSettings) {
            this.setState({
                enable_integration_settings: false,
                isSalesforceIntegrationDisabled: true,
            });
        } else {
            this.setState({
                enable_integration_settings: true,
                isSalesforceIntegrationDisabled: false,
                listeners: listeners || VALIDATION_MAP.listeners.defaultOption,
            });
        }
    }

    handleFocus(e) {
        const { name } = e.target;
        this.setState({
            focusedElement: name,
        });
    }

    handleKeyDown(e) {
        const { key, keyCode, target } = e;
        const errors = {};
        const { lastStrokedKey } = this.state;

        if (VALIDATION_MAP[target.name].type === 'number') {
            if (Object.keys(FORBIDDEN_KEYS).includes(key)) {
                e.preventDefault();
            }

            if (/\D/.test(key) && !Object.values(ALLOWED_KEYS).includes(keyCode)) {
                errors[target.name] = VALIDATION_ERRORS.typeError('numbers');
            }
        }

        if (VALIDATION_MAP[target.name].type === 'float') {
            this.setState({
                lastStrokedKey: key,
            });

            if (
                (key === '.' && lastStrokedKey === '.') ||
                (target.value.includes('.') && key === '.')
            ) {
                e.preventDefault();
            }

            if (Object.keys(FORBIDDEN_FLOAT_KEYS).includes(key)) {
                e.preventDefault();
            }

            if (/\D/.test(key) && !Object.values(ALLOWED_FLOAT_KEYS).includes(keyCode)) {
                errors[target.name] = VALIDATION_ERRORS.typeError('numbers');
            }
        }

        this.setState({
            errors,
        });
    }

    handleSelect({ name, value }) {
        this.setState({
            [name]: value,
            errors: {},
        });
    }

    handleTokens(tokens, name) {
        const newTokens = tokens;
        this.setState({
            [name]: newTokens,
            errors: {},
        });
        this.getInputErrors(name, newTokens);
    }

    handleTextChange(name, e) {
        this.setState({
            [name]: e,
            errors: {},
        });
    }

    handleDateChange(date) {
        this.setState({
            expiration_date: date,
        });
    }

    validateForm() {
        const keys = Object.keys(VALIDATION_MAP);
        const errors = {};
        const { ...state } = this.state;

        keys.forEach(key => {
            if (this.getInputErrors(key, state[key])[key]) {
                errors[key] = this.getInputErrors(key, state[key])[key];
            }
        });

        if (
            state.cms === CMS_OPTIONS.control &&
            !state.static_inventory &&
            !state.digital_inventory
        ) {
            errors.static_inventory = VALIDATION_ERRORS.inventoryError;
            errors.digital_inventory = VALIDATION_ERRORS.inventoryError;
        }

        this.setState({
            errors,
        });

        return Object.keys(errors).length === 0;
    }

    handleSubmit() {
        const { ...state } = this.state;

        if (this.validateForm()) {
            const keys = Object.keys(VALIDATION_MAP).filter(
                key => !VALIDATION_MAP_HELPERS.includes(key)
            );
            const data = {};

            if (state.provider_domain_id) {
                this.handleDomainFiller(state.provider_domain_id, state);
                this.handleProviderId(state.provider_domain_id);
                this.handleGetLoopPolicies(state.provider_domain_id, state);
            }

            if (state.isEditing > 0) {
                this.handleEditSubmit();
            } else {
                if (state.enable_integration_settings) {
                    data.integration_settings = {
                        listeners: JSON.parse(state.listeners),
                        salesforce_instance: state.salesforce_instance,
                    };
                } else {
                    data.integration_settings = {};
                }

                keys.forEach(key => {
                    if (key === 'billing_type') {
                        data.billing_type = DomainEditor.parseBillingType(state[key]);
                    } else if (key === 'enable_audit_log') {
                        if (state.enable_audit_log) {
                            data.integration_settings.audit_log_enabled = state.enable_audit_log;
                        }
                    } else {
                        data[key] = state[key];
                    }
                });

                this.setState({
                    openConfirmModal: true,
                    editedData: data,
                });
            }
        }
    }

    handleEditSubmit() {
        const { ...state } = this.state;
        const { targetDomain } = this.props;
        const data = {};
        const keys = Object.keys(VALIDATION_MAP);

        keys.forEach(key => {
            if (state[key] !== targetDomain[key]) {
                if (
                    key === 'enable_integration_settings' ||
                    key === 'listeners' ||
                    key === 'salesforce_instance' ||
                    key === 'enable_audit_log'
                ) {
                    if (state.enable_integration_settings) {
                        if (!data.integration_settings) data.integration_settings = {};
                        data.integration_settings.listeners = JSON.parse(state.listeners);
                        data.integration_settings.salesforce_instance = state.salesforce_instance;
                    }

                    if (state.enable_audit_log) {
                        if (!data.integration_settings) data.integration_settings = {};
                        data.integration_settings.audit_log_enabled = state.enable_audit_log;
                    }

                    if (!state.enable_integration_settings && !state.enable_audit_log) {
                        data.integration_settings = {};
                    }
                } else if (key === 'billing_type') {
                    data.billing_type = DomainEditor.parseBillingType(state[key]);
                } else {
                    data[key] = state[key];
                }
            }
        });

        const filteredData = Object.keys(data).reduce((obj, key) => {
            const newObject = obj;
            if (!VALIDATION_MAP_HELPERS.includes(key)) {
                newObject[key] = data[key];
            }
            return newObject;
        }, {});

        if (Object.keys(data).length > 0) {
            this.setState({
                openConfirmModal: true,
                editedData: filteredData,
                id: targetDomain.id,
            });
        } else {
            this.setState({
                errors: { footer: 'No new data to save' },
            });
        }
    }

    handleConfirmSaveDomain() {
        const { editedData, id } = this.state;
        this.setState({
            openConfirmModal: false,
        });
        if (id) {
            this.saveEditedData(editedData, id);
        } else {
            this.save(editedData);
        }
    }

    handleCancelSaveDomain() {
        this.setState({
            openConfirmModal: false,
        });
    }

    handleConfirmSalesforceDisabling() {
        this.setState({
            isSalesforceIntegrationDisabled: false,
            integration_settings: {},
        });
    }

    handleCancelSalesforceDisabling() {
        this.setState({
            isSalesforceIntegrationDisabled: false,
            enable_integration_settings: true,
        });
    }

    handleCancel() {
        const { history, setServerErrors } = this.props;
        history.push('/domains');
        setServerErrors('');
    }

    save(data) {
        const { addNewDomain } = this.props;

        addNewDomain(data);
    }

    saveEditedData(data, id) {
        const { editDomain } = this.props;
        editDomain(data, id);
    }

    async handleDomainFiller(providerId, targetDomain) {
        const { checkHasFiller } = this.props;
        await checkHasFiller(providerId, targetDomain);
    }

    async handleProviderId(providerId) {
        const { checkProviderId } = this.props;
        await checkProviderId(providerId);
    }

    async handleGetLoopPolicies(providerId, targetDomain) {
        const { getLoopPolicies } = this.props;
        await getLoopPolicies(providerId, targetDomain);
    }

    handleDisplayData() {
        const { data } = this.props;
        const { isEditing } = this.state;
        const renderData = [
            <div className={s.confirmationTitle} key="confirmationTitle">
                {isEditing ? 'Domain Updated' : 'Domain Created'}
            </div>,
        ];

        Object.keys(data).forEach(key => {
            if (key === 'feature_flags') {
                this.renderFeatureFlagModifications(data[key], renderData);
            } else {
                let value = data[key];
                if (value === '') {
                    value = 'Not specified';
                } else {
                    value = JSON.stringify(value);
                }

                renderData.push(
                    <div className={s.confirmationItem} key={key}>
                        {key}: {value}
                    </div>
                );
            }
        });

        return renderData;
    }

    handleDisplayEditedData() {
        const {
            editedData,
            underperforming_rebalancing_threshold_pct: underPerformingRebalancingThresholdPct,
            overperforming_rebalancing_threshold_pct: overPerformingRebalancingThresholdPct,
            provider_domain_id: providerDomainId,
        } = this.state;
        const { data, isLoading, response, hasValidProviderId } = this.props;
        const renderData = [
            <div className={s.confirmationTitle} key="confirmationTitle">
                Confirm editing of the following fields?
            </div>,
        ];

        if (editedData) {
            const items = [];
            Object.keys(editedData).forEach(key => {
                if (key === 'feature_flags') {
                    this.renderFeatureFlagModifications(editedData[key], renderData);
                } else {
                    let value = editedData[key];
                    if (value === '') {
                        value = 'Not specified';
                    } else {
                        value = JSON.stringify(value);
                    }

                    if (value.charAt(0) === '[') {
                        value = value.replace(/,/g, ', ');
                    }
                    items.push(
                        <tr key={key}>
                            <td className={s.confirmationItem}>{key.replace(/_/g, ' ')}</td>
                            <td className={s.confirmationItem}>{value}</td>
                        </tr>
                    );
                }
            });
            if (items) {
                renderData.push(
                    <div className={s.confirmationContent} key="ConfirmationItems">
                        <table>
                            <tbody>{items}</tbody>
                        </table>
                    </div>
                );
            }
        }

        if (isLoading) {
            renderData.push(
                <div className={s.warningMessage} key="pleaseWait">
                    Checking information. Please wait
                </div>
            );
        }
        if (response.getLoopPolicies) {
            if (response.getLoopPolicies.length) {
                renderData.push(
                    <div className={s.warningMessage} key="OverbookingWarn">
                        Warning: There are loop policies with allow overbooking set to off.
                    </div>
                );
            }
        }
        if (data.missingFillers) {
            if (data.missingFillers.length) {
                renderData.push(
                    <div className={s.warningMessage} key="fillerWarn">
                        Warning: There are loop policies with no filler content for this domain
                    </div>
                );

                // Commented this out util UX comes back with a suggestion. When many loop policies
                // where listed, this would cause the modal to go beyond the fold and need the list
                // closed before we could confirm/cancel
                //
                // const fillerRows = [];
                //
                // data.missingFillers.forEach(key => {
                //     fillerRows.push(<p>{key}</p>);
                // });
                //
                // renderData.push(
                //     <Collapsible
                //         trigger="Show / Hide loop ID's with missing filler content"
                //         className={s.collapsible}
                //         openedClassName={s.collapsible_open}
                //         triggerClassName={s.collapsible_trigger}
                //         triggerOpenedClassName={s.collapsible_trigger_open}
                //         contentOuterClassName={s.collapsible_content_outer}
                //         contentInnerClassName={s.collapsible_content_inner}
                //         key="collapse"
                //     >
                //         {fillerRows}
                //     </Collapsible>
                // );
            }
        }

        if (providerDomainId && !hasValidProviderId) {
            renderData.push(
                <div className={s.warningMessage} key="providerDomainWarn">
                    Warning: No domain exists in Broadsign Control matching the provider domain id
                </div>
            );
        }

        if (!underPerformingRebalancingThresholdPct && !overPerformingRebalancingThresholdPct) {
            renderData.push(
                <div className={s.warningMessage} key="rebalancingWarn">
                    Warning: Rebalancing is not activated on this domain
                </div>
            );
        }
        return renderData;
    }

    handleAdditionalCurrencies() {
        const additionalCurrencies =
            (this.ref_additional_currencies_select &&
                this.ref_additional_currencies_select.current &&
                this.ref_additional_currencies_select.current.select &&
                this.ref_additional_currencies_select.current.select.state &&
                this.ref_additional_currencies_select.current.select.state.selectValue) ||
            {};
        const primaryCurrency =
            (this.ref_primary_currency_select &&
                this.ref_primary_currency_select.current &&
                this.ref_primary_currency_select.current.select &&
                this.ref_primary_currency_select.current.select.state &&
                this.ref_primary_currency_select.current.select.state.selectValue &&
                this.ref_primary_currency_select.current.select.state.selectValue[0] &&
                this.ref_primary_currency_select.current.select.state.selectValue[0].value) ||
            {};

        if (additionalCurrencies.length > 0 && Object.keys(primaryCurrency).length === 0) {
            this.ref_additional_currencies_select.current.select.clearValue();
        }

        if (
            additionalCurrencies.some(item => {
                return item.value === primaryCurrency;
            })
        ) {
            this.ref_additional_currencies_select.current.select.setValue(
                additionalCurrencies.filter(item => {
                    return item.value !== primaryCurrency;
                })
            );
        }
    }

    handleReachSynchStateChange() {
        const {
            reach_synchronization_enabled: rse,
            reach_publisher_id: rsi,
            use_player_identifiers_in_reach_ad_requests: rsi2,
        } = this.state;

        if (rsi && !rse) {
            this.setState({ reach_publisher_id: null });
        }

        if (rsi2 && !rse) {
            this.setState({ use_player_identifiers_in_reach_ad_requests: false });
        }
    }

    handleContentManagementSystem() {
        const {
            provider_domain_id: providerDomainId,
            static_inventory: staticInventory,
            digital_inventory: digitalInventory,
        } = this.state;
        const cms =
            (this.ref_cms_select &&
                this.ref_cms_select.current &&
                this.ref_cms_select.current.select &&
                this.ref_cms_select.current.select.state &&
                this.ref_cms_select.current.select.state.selectValue &&
                this.ref_cms_select.current.select.state.selectValue[0] &&
                this.ref_cms_select.current.select.state.selectValue[0].value) ||
            {};

        if ((providerDomainId || staticInventory || digitalInventory) && cms === 'Other') {
            this.setState({
                provider_domain_id: '',
                static_inventory: false,
                digital_inventory: false,
            });
        }
    }

    handleCreateDigitalInventory({ digital_inventory: prevDigitalInventory }) {
        const { digital_inventory: digitalInventory } = this.state;

        if (!digitalInventory && !!prevDigitalInventory) {
            this.setState({ provider_domain_id: '' });
        }
    }

    renderFeatureFlagModifications(newFlags, renderedElements) {
        const { targetDomain } = this.props;

        const oldFlags = 'feature_flags' in targetDomain ? targetDomain.feature_flags : [];

        newFlags.forEach(newFlag => {
            const oldFlag = oldFlags.find(value => value.id === newFlag.id);

            if (oldFlag === undefined || newFlag.enabled !== oldFlag.enabled) {
                renderedElements.push(
                    <div className={s.confirmationItem} key={newFlag.name}>
                        {newFlag.name}: {newFlag.enabled === true ? 'enabled' : 'disabled'}
                    </div>
                );
            }
        });
    }

    renderFormForActiveSetting(key) {
        const { ...state } = this.state;

        return VALIDATION_MAP[key].parent ? state[VALIDATION_MAP[key].parent] : true;
    }

    renderInput(key) {
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;
        const newValidationMap = this.updateValidationMap();

        return (
            <Input
                innerRef={this[`ref_${key}`]}
                key={key}
                dataCy={key}
                className={key}
                description={VALIDATION_MAP[key].description}
                type="text"
                name={key}
                labelText={DomainEditor.renderFieldLabel(
                    newValidationMap[key].label,
                    newValidationMap[key].required
                )}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                required={VALIDATION_MAP[key].required}
                value={state[key] ? state[key] : ''}
                error={state.errors[key] || serverError[key]}
                disabled={isLoading || newValidationMap[key].disabled}
                maxLength={VALIDATION_MAP[key].maxLength}
            />
        );
    }

    renderInputNumber(key) {
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;
        const newValidationMap = this.updateValidationMap();
        const isDigitalInventoryDisabled = this.isDigitalInventoryDisabled();
        const shouldReachPublisherIDBeDisabled = this.shouldReachPublisherIDBeDisabled();
        const noAccessDisable = this.shouldNoAccessDisableComponent(key);
        const label = newValidationMap[key].required
            ? `${newValidationMap[key].label} *`
            : newValidationMap[key].label;

        if (key === 'id' && !state.id) {
            return <></>;
        }
        return (
            <Input
                innerRef={this[`ref_${key}`]}
                key={key}
                dataCy={key}
                className={key}
                description={VALIDATION_MAP[key].description}
                type="number"
                name={key}
                labelText={label}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                required={newValidationMap[key].required}
                value={state[key] ? state[key] : ''}
                error={state.errors[key] || serverError[key]}
                disabled={
                    key === 'id' ||
                    (VALIDATION_MAP[key].label === 'Control Domain ID' &&
                        isDigitalInventoryDisabled) ||
                    noAccessDisable ||
                    (key === 'reach_publisher_id' && shouldReachPublisherIDBeDisabled) ||
                    newValidationMap[key].disabled
                        ? true
                        : isLoading
                }
                min={VALIDATION_MAP[key].min}
                max={VALIDATION_MAP[key].max}
            />
        );
    }

    renderCheckbox(key) {
        const noAccessDisable = this.shouldNoAccessDisableComponent(key);
        const { ...state } = this.state;
        const { isLoading, serverError, targetDomain } = this.props;
        const inventoryKeys = Object.values(INVENTORY_TYPES);
        const newValidationMap = this.updateValidationMap();
        const shouldReachSynchronizationBeDisabled = this.shouldReachSynchronizationBeDisabled();
        const isUsePlayerIdentifiersInReachAdRequestsDisabled = this.isUsePlayerIdentifiersInReachAdRequestsDisabled();

        return (
            <Checkbox
                innerRef={this[`ref_${key}`]}
                key={key}
                dataCy={key}
                id={key}
                className={key}
                description={VALIDATION_MAP[key].description}
                onChange={this.handleChange}
                disabled={
                    (state.isEditing && inventoryKeys.includes(key) && targetDomain[key]) ||
                    noAccessDisable ||
                    (key === 'use_player_identifiers_in_reach_ad_requests' &&
                        isUsePlayerIdentifiersInReachAdRequestsDisabled) ||
                    (key === 'reach_synchronization_enabled' &&
                        shouldReachSynchronizationBeDisabled) ||
                    newValidationMap[key].disabled
                        ? true
                        : isLoading
                }
                checked={state[key] ? state[key] : false}
                labelText={VALIDATION_MAP[key].label}
                warningMessage={state[key] ? VALIDATION_MAP[key].warningMessage : ''}
                error={
                    inventoryKeys.includes(key)
                        ? state.errors[key] || serverError.inventory_type || serverError[key]
                        : state.errors[key] || serverError[key]
                }
            />
        );
    }

    renderFeatureFlagList(key) {
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;
        const inventoryKeys = Object.values(INVENTORY_TYPES);
        const items = [];

        const flags = state[key];
        if (flags === undefined) {
            return items;
        }

        flags.forEach(ff => {
            items.push(
                <div className={s.unsafeSetting} key={ff.name}>
                    <Checkbox
                        innerRef={this[`ref_${key}`]}
                        key={ff.name}
                        dataCy={ff.name}
                        id={ff.name}
                        className={key}
                        onChange={this.handleFeatureFlagChange}
                        disabled={isLoading}
                        checked={ff.enabled}
                        labelText={ff.name}
                        error={
                            inventoryKeys.includes(key)
                                ? state.errors[key] ||
                                  serverError.inventory_type ||
                                  serverError[key]
                                : state.errors[key] || serverError[key]
                        }
                    />
                    <p className={s.unsafeText}>{ff.description}</p>
                </div>
            );
        });

        return items;
    }

    renderSelect(key) {
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;
        const newValidationMap = this.updateValidationMap();

        return (
            <Select
                innerRef={this[`ref_${key}`]}
                selectRef={this[`ref_${key}_select`]}
                key={key}
                dataCy={key}
                className={key}
                description={VALIDATION_MAP[key].description}
                label={DomainEditor.renderFieldLabel(
                    VALIDATION_MAP[key].label,
                    newValidationMap[key].required
                )}
                options={newValidationMap[key].options}
                onSelect={this.handleSelect}
                name={key}
                required={newValidationMap[key].required}
                value={state[key]}
                error={state.errors[key] || serverError[key]}
                onClickOutside={this.handleBlur}
                disabled={isLoading || newValidationMap[key].disabled}
                multiple={VALIDATION_MAP[key].multiple}
            />
        );
    }

    renderTokenField(key) {
        const noAccessDisable = this.shouldNoAccessDisableComponent(key);
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;

        return (
            <TokenField
                innerRef={this[`ref_${key}`]}
                key={key}
                dataCy={key}
                className={key}
                name={key}
                description={VALIDATION_MAP[key].description}
                onChange={this.handleTokens}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                disabled={noAccessDisable || isLoading}
                labelText={VALIDATION_MAP[key].label}
                error={state.errors[key] || serverError[key]}
                valueType={VALIDATION_MAP[key].valueType}
                values={state[key]}
            />
        );
    }

    renderTextEditor(key) {
        const noAccessDisable = this.shouldNoAccessDisableComponent(key);
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;
        return (
            <TextEditor
                innerRef={this[`ref_${key}`]}
                key={key}
                dataCy={key}
                className={key}
                description={VALIDATION_MAP[key].description}
                onChange={this.handleTextChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                disabled={noAccessDisable || isLoading}
                labelText={VALIDATION_MAP[key].label}
                error={state.errors[key] || serverError[key]}
                defaultText={state[key]}
            />
        );
    }

    renderTextArea(key) {
        const noAccessDisable = this.shouldNoAccessDisableComponent(key);
        const { ...state } = this.state;
        const { isLoading, serverError } = this.props;
        const label = VALIDATION_MAP[key].required
            ? `${VALIDATION_MAP[key].label} *`
            : VALIDATION_MAP[key].label;

        return (
            <TextArea
                innerRef={this[`ref_${key}`]}
                id={key}
                key={key}
                dataCy={key}
                className={key}
                description={VALIDATION_MAP[key].description}
                name={key}
                labelText={label}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                required={VALIDATION_MAP[key].required}
                value={state[key] ? state[key] : ''}
                error={state.errors[key] || serverError[key]}
                disabled={noAccessDisable || isLoading}
            />
        );
    }

    renderDatePicker(key) {
        const noAccessDisable = this.shouldNoAccessDisableComponent(key);
        const { ...state } = this.state;
        const label = VALIDATION_MAP[key].required
            ? `${VALIDATION_MAP[key].label} *`
            : VALIDATION_MAP[key].label;

        return (
            <DatePicker
                innerRef={this[`ref_${key}`]}
                key={key}
                dataCy={key}
                name={key}
                labelText={label}
                value={state[key]}
                description={VALIDATION_MAP[key].description}
                disabled={noAccessDisable}
                onDateChange={this.handleDateChange}
            />
        );
    }

    render() {
        const { errors, openConfirmModal, isEditing, isSalesforceIntegrationDisabled } = this.state;
        const { isLoading, serverError, data, isSuccess, targetDomain } = this.props;
        const renderData = this.handleDisplayData();
        const renderEditedData = this.handleDisplayEditedData();
        const generatedComponents = this.generateComponents();

        const hasSalesforceIntegration = isEditing && targetDomain.enable_integration_settings;

        return (
            <div className={s.domainEditorWrapper}>
                <div className={s.domainEditor}>
                    <div className={s.titleWrapper}>
                        {isEditing ? 'Edit domain' : 'Add New Domain'}
                    </div>
                    <form
                        className={s.domainForm}
                        data-cy="domainForm"
                        id="domainForm"
                        tabIndex="-1"
                    >
                        {generatedComponents.standard}
                        <div className={s.directWrapper}>
                            <p className={s.unsafeWarning} data-cy="directSetting">
                                Direct Settings
                            </p>
                            {generatedComponents.direct}
                        </div>
                        <div className={s.unsafeWrapper}>
                            <p className={s.unsafeWarning} data-cy="unsafeSection">
                                <Icon icon="warning" fill={variables.warning} size={26} />
                                Unsafe Direct Settings: Change with Caution
                                <Icon icon="warning" fill={variables.warning} size={26} />
                            </p>
                            {generatedComponents.unsafe}
                        </div>

                        {generatedComponents.feature_flags.length !== 0 && (
                            <div className={s.unsafeWrapper} data-cy="feature_flags_list">
                                <p className={s.unsafeWarning}>
                                    <Icon icon="warning" fill={variables.warning} size={26} />
                                    Feature Flags: Change with Caution
                                    <Icon icon="warning" fill={variables.warning} size={26} />
                                </p>
                                {generatedComponents.feature_flags}
                            </div>
                        )}

                        <div className={s.aquariusWrapper} data-cy="aquarius_settings">
                            <p className={s.unsafeWarning}>
                                <Icon icon="warning" fill={variables.warning} size={26} />
                                Aquarius Settings: Only for Eligible Customers
                                <Icon icon="warning" fill={variables.warning} size={26} />
                            </p>
                            {generatedComponents.aquarius}
                        </div>

                        <div className={s.footer}>
                            <div className={s.requiredMessage}>* Required Fields</div>
                            {errors.footer && <ErrorMessage message={errors.footer} />}
                            {Object.keys(serverError) && Object.keys(serverError).length !== 0 && (
                                <ErrorMessage message={serverError.footer} />
                            )}
                            <div className={s.footerActions}>
                                <Button
                                    className="cancel"
                                    type="submit"
                                    label="Cancel"
                                    onClick={this.handleCancel}
                                    disabled={isLoading}
                                    dataCy="cancel"
                                />
                                <Button
                                    className="submit"
                                    type="submit"
                                    label="Submit"
                                    onClick={this.handleSubmit}
                                    disabled={isLoading}
                                    dataCy="submit"
                                />
                            </div>
                        </div>
                        {Object.keys(data).length !== 0 && isSuccess && (
                            <Modal onClose={this.closeModal}>{renderData}</Modal>
                        )}
                        {hasSalesforceIntegration && isSalesforceIntegrationDisabled && (
                            <Modal>
                                Confirm disabling Salesforce integration?
                                <div className={s.confirmActions}>
                                    <Button
                                        className="cancel"
                                        dataCy="cancelSalesforceDisabling"
                                        label="Cancel"
                                        onClick={this.handleCancelSalesforceDisabling}
                                    />
                                    <Button
                                        className="confirm"
                                        dataCy="confirmSalesforceDisabling"
                                        label="Confirm"
                                        onClick={this.handleConfirmSalesforceDisabling}
                                    />
                                </div>
                            </Modal>
                        )}
                        {openConfirmModal && (
                            <Modal>
                                {renderEditedData}
                                <div className={s.confirmActions}>
                                    <Button
                                        className="cancel"
                                        dataCy="cancel"
                                        label="Cancel"
                                        onClick={this.handleCancelSaveDomain}
                                    />
                                    <Button
                                        className="confirm"
                                        dataCy="editConfirm"
                                        label="Confirm"
                                        disabled={isLoading}
                                        onClick={this.handleConfirmSaveDomain}
                                    />
                                </div>
                            </Modal>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

DomainEditor.propTypes = {
    targetDomain: PropTypes.shape({}),
    addNewDomain: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    serverError: PropTypes.shape({}),
    data: PropTypes.shape({}),
    response: PropTypes.shape({}),
    hasValidProviderId: PropTypes.bool,
    isSuccess: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setServerErrors: PropTypes.func.isRequired,
    editDomain: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
    checkHasFiller: PropTypes.func.isRequired,
    checkProviderId: PropTypes.func.isRequired,
    getLoopPolicies: PropTypes.func.isRequired,
};

DomainEditor.defaultProps = {
    data: {},
    targetDomain: {},
    serverError: {},
    response: {},
    hasValidProviderId: false,
};

const mapStateToProps = state => {
    return {
        data: state.domainReducer.data,
        response: state.domainReducer.response,
        hasValidProviderId: state.domainReducer.hasValidProviderId,
        isLoading: state.commonReducer.isLoading,
        serverError: state.commonReducer.serverError,
        domains: state.getDomainsReducer.domains,
        isSuccess: state.domainReducer.isSuccess,
        targetDomain: state.domainReducer.targetDomain,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addNewDomain: data => dispatch(domainActions.addNewDomain(data)),
        editDomain: (data, id) => dispatch(domainActions.editDomain(data, id)),
        setSuccess: () => dispatch(domainActions.setSuccess()),
        resetForm: () => dispatch(domainActions.resetForm()),
        setServerErrors: errors => dispatch(commonActions.setServerErrors(errors)),
        checkHasFiller: (providerId, targetDomain) =>
            dispatch(domainActions.checkHasFiller(providerId, targetDomain)),
        checkProviderId: providerId => dispatch(domainActions.checkProviderId(providerId)),
        getLoopPolicies: (providerId, targetDomain) =>
            dispatch(domainActions.getLoopPolicies(providerId, targetDomain)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DomainEditor);
